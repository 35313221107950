import React, { useState, useEffect } from 'react';
import { graphql } from "gatsby"
import { SliceZone } from '@prismicio/react'
import * as prismicH from "@prismicio/helpers";
import { PrismicRichText } from '@prismicio/react'
import moment from 'moment';
import { Layout } from '../../components/Layout'
import { components } from '../../slices'
import { BlogListItem } from '../../components/BlogListItem';

function BlogItem(props) {
  console.log('BlogItem props: ', props)
  const { prismicBlogItem, prismicBlog } = props.data
  const [blogItems,setBlog] = useState([])
  
  useEffect( ()=> {
    const docs = props.data.allPrismicBlogItem || []
    const now = moment()
    const filtered = docs.nodes.filter(item => {
      console.log('item: ', item)
      const data = item.data
      // console.log('start: ',data.start_time)
      // console.log('finish: ',data.finish_time)
      // const startTime = moment(data.start_time)
      // const finishTime = moment(data.finish_time)
      // return (startTime < now)
      return true;
    })
    console.log('portfolio: ', filtered)
    setBlog(filtered)
  }, [props.data.allPrismicBlogItem])
  
  if (!props.data) return null

  const topMenu = props.data.prismicTopmenu || {}
  const footer = props.data.prismicFooter || {}
  const blog = props.data.prismicBlog || {}

  const isTMWhite = true

  // todo: make component
  if (!prismicBlogItem) {
    const pageUrl = `/blog/${props.pageContext.uid}`;
    return (
      <Layout topMenu={topMenu.data} isTMWhite={isTMWhite} footer={footer} seo={{}}>
        <div className="bg-light">
          <div className="container" style={{paddingTop:"120px",paddingBottom:"80px"}}>
            {'Sory, this page not yet translated. '}
            <a href={pageUrl} >Default page</a>
          </div>
        </div>
      </Layout>
    );
  }

  const blogItem = prismicBlogItem.data
  // const eventInfoSlice = event.body.find(item => item.slice_type === 'event_info');
  const seo = {
    title: blogItem.seo_title || prismicBlogItem.data.title,
    description: blogItem.seo_description || prismicBlogItem.data.subtitle,
    keywords: blogItem.seo_keywords,
    no_robots: blogItem.no_robots,
  }
  console.log('seo: ', seo)

  const {title, subtitle, text, date, cover_img, body, author_name, author_link, author_img} = blogItem

  //Firefox GIF fix
  let cover_img_url = cover_img.url
  
  if (new URL(cover_img_url).pathname.endsWith(".gif")) {
    cover_img_url= prismicH.asImageSrc(cover_img, {auto: "compress"})
  }

  return (
    <Layout topMenu={topMenu.data} isTMWhite={isTMWhite} footer={footer} seo={seo}>
      <div className="bg-light">
        <div className="container" style={{paddingTop:"120px",paddingBottom:"80px"}}>
        <div className="container mb-3 d-flex align-items-center">
          <a className="fw-bold h6 mb-0" href="/">Home</a>
          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="#404050bf" class="text-dark mx-1 bi bi-chevron-right" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/></svg>
          <a className="fw-bold h6 mb-0" href="/blog">Blog</a>
          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="#404050bf" class="mx-1 bi bi-chevron-right" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/></svg>
          <span className="fw-bold h6 mb-0 opacity-75">{title}</span>
        </div>
        <div className="container py-5 px-3 px-sm-4 px-lg-5 bg-white">
          <h1 className="h2 fw-bold">{title}</h1>
          <h5>{subtitle}</h5>
          <div className="mt-4 d-flex">
            <a href={author_link.url} target="_blank">
              <img src={author_name ? (author_img.url ? author_img.url : "/icons/icon-user.png") : "/favicon.ico"} height="48" width="48" alt={author_img.url} title={(author_name ? author_name : "AK Media Team") + " image"}/>
            </a>
            <div className="ms-3 d-flex flex-column justify-content-between">
              <a href={author_link.url} target="_blank" className="text-decoration-none text-body">
                <h5 className="mb-0 fw-bold">{author_name ? author_name : "AK Media Team"}</h5>
              </a>
              <div className="" style={{fontSize:"14px"}}>{date}</div>
            </div>
          </div>
          <hr/>
          <div className="my-5 w-100 text-center">
            <img style={{maxHeight:"360px"}} src={cover_img_url} alt={cover_img.alt} title={cover_img.alt}/>
          </div>

          <SliceZone slices={body} components={components} />
        </div>
        <div className="mt-5 container">
          <h4 className="page-title">{"Recent articles:"}</h4>
        </div>
        <div className="mt-4 container py-5 px-3 px-sm-4 px-lg-5 bg-white"> 
          {
            blogItems.map((item, index) => (
              <div key={index} className="">
                <BlogListItem item={item} />
              </div>))
          }
        </div>
        </div>
      </div>
    </Layout>
  )
}

export default BlogItem

export const query = graphql`
  query($uid: String!, $lang: String) {
    # prismicBlogItemDefault(id: { eq: $id }) {}
    prismicBlogItem(uid: { eq: $uid }, lang: { eq: $lang }) {
      id
      uid
      lang
      type
      data {
        title
        subtitle
        date
        cover_img {
          url
          alt
        }
        author_name
        author_link {
          url
        }
        author_img {
          url
        }
        seo_title
        seo_description
        seo_keywords

        body {
          ...BlogItemDataBodyBlogText
        }
      }
    }
    prismicTopmenu {
      ...TopmenuFragment
    }
    prismicFooter {
      ...FooterFragment
    }

    allPrismicBlogItem(
    sort: { fields: data___date, order: DESC }
    limit: 3
    skip: 0
    filter: {uid: {ne: $uid}}
    ) {
      nodes {
        _previewable
        uid
        url
        data {
          title
          subtitle
          date
          cover_img {
            alt
            url
          }
          author_name
          author_link {
            url
          }
          author_img {
            url
          }
          #sort
          body {
            ... on PrismicBlogItemDataBodyBlogText {
              id
              slice_type
              primary {
                text {
                  richText
                  text
                }
              }
            }
          }
        }
      }
    }
  }
`